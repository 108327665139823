import React from 'react'
import { Button, ConfigProvider } from 'antd'

import { TBtnPrimary } from './Types'

export const BtnPrimary:React.FC<TBtnPrimary> = ( { text, fOnClick } ) => {
  return(
    <ConfigProvider
        theme={ {
          components:{
            Button:{
              colorPrimary:'#FFFFFF00',
              colorPrimaryHover:'#4096ff',
              primaryShadow:'0px 0px 5px 0px #FFFFFF',
              colorPrimaryBorder:'#4096ff',
            }
          },
          token:{ fontSize:18 }
        } }>
      <Button type='primary' onClick={ () => fOnClick() }>{ text }</Button>
    </ConfigProvider>
  )
}