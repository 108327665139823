import React from "react";
import { PrimaryContainer } from "../../../../Shared/Styles/PrimaryStylesGroup/Container";
import { IntegrationResultData } from "../../../../Shared/Localization/IntellessiPage/integration.result";
import { Typography, Row } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { CSSCustomBlockList, CSSCircle } from "./styles";
import { size } from "../../../../Shared/consts/MediaQueries";

const { Title, Paragraph } = Typography;

export const IntegrationResult: React.FC = () => {
  return (
    <PrimaryContainer $margin={40}>
      <Title
        level={3}
        style={{
          color: "#13087A",
          fontSize: size.mobileL >= window.innerWidth ? "35px" : "50px",
        }}
      >
        {IntegrationResultData.title}
      </Title>

      <Row justify={"center"}>
        {IntegrationResultData.list.map((str: string, index: number) => (
          <CSSCustomBlockList key={index}>
            <CSSCircle>
              <CheckOutlined style={{ fontSize: "25px", color: "#FFFFFF" }} />
            </CSSCircle>

            <Paragraph style={{ margin: "0px 0px 0px 40px", fontSize: "18px" }}>
              {str}
            </Paragraph>
          </CSSCustomBlockList>
        ))}
      </Row>
    </PrimaryContainer>
  );
};
