import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { ReactComponent as OCurlyArrow } from 'Shared/Assets/images/OtherIcons/CurlyArrow/CurlyArrow_1.svg'
import { ReactComponent as TCurlyArrow } from 'Shared/Assets/images/OtherIcons/CurlyArrow/CurlyArrow_2.svg'
import { ReactComponent as ThCurlyArrow } from 'Shared/Assets/images/OtherIcons/CurlyArrow/CurlyArrow_3.svg'
import { ReactComponent as FCurlyArrow } from 'Shared/Assets/images/OtherIcons/CurlyArrow/CurlyArrow_4.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; align-items:center; justify-content:center;
    margin:20px auto;

    @media ${ device.maxMobileL } { flex-direction:column; }
  `

const Curly = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:250px; height:180px; margin:20px;
    display:flex; align-items:center; justify-content:center;
    position:relative;

    p{
      width:120px;
      color:#FFFFFF; text-align:center;
      position:relative; z-index:10;
    }

    figure{
      position:absolute; z-index:0;
      svg{ width:100%; height:auto; position:relative; }
    }

    @media ${ device.maxMobileL } { figure{ transform:rotate(90deg); } }
  `

/*
    TODO: Сделать вывод блок через цикл!
*/

export const DataTransferProcess:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title='Процесс передачи данных' />

      <Container>
        <Curly>
          <figure><OCurlyArrow /></figure>
          <p>В ПК САБПЭК формируется задание для мобильного приложения</p>
        </Curly>

        <Curly>
          <figure><TCurlyArrow /></figure>
          <p>Сотрудник получает в мобильном приложении задание и наполняет его данными</p>
        </Curly>

        <Curly>
          <figure><ThCurlyArrow /></figure>
          <p>Задание отправляется с мобильного приложения в ПК САБПЭК</p>
        </Curly>

        <Curly>
          <figure><FCurlyArrow /></figure>
          <p>Данные из САБПЭК через интеграционную шину отправляются в систему заказчика</p>
        </Curly>
      </Container>
    </PrimaryContainer>
  )
}