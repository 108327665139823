import React from 'react'

import { TSequenceBtnDDoc, IDocument } from './Types'
import { CSSSequenceBtnDDoc } from './Styles'
import { iconDocs } from './documents'

import { BtnDDoc } from 'Shared/UI/BtnGroup/BtnDDoc'

export const SequenceBtnDDoc:React.FC<TSequenceBtnDDoc> = ( { data } ) => {
  return(
    <CSSSequenceBtnDDoc>
      {
        data.map( ( items:IDocument, _ ) =>
            <BtnDDoc
              title={ items.title }
              link={ iconDocs( items.type ) }
              file={ items.file }
              type={ items.type}
            />
          )
      }
    </CSSSequenceBtnDDoc>
  )
}