import React from 'react'

import { EffectsIntroductionBlock } from 'Shared/UI/BlocksGroup/EffectsIntroductionBlock'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { Data, IList } from '../Data/data'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; flex-wrap:wrap; justify-content:center;
    margin:20px;
  `

export const EffectsIntroduction:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title={ Data.title } />

      <Container>
        {
          Data.list.map( (item:IList, index:number) =>
              <EffectsIntroductionBlock
                  key={ index }
                  title={ item.title }
                  text={ item.description }
                />
          )
        }
      </Container>
    </PrimaryContainer>
  )
}