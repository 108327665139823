import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const Span = styled.span`
    border:1px solid #CFCFCF;
    display:block; width:20px;
    margin:3px;
    transition:.4s;
  `

export const BtnBox = styled.div`
    border: ${ Grids ? '1px solid red' : 'none' };
    border-radius:10px;
    width:35px; height:35px; margin-right:10px;
    display:flex; flex-direction:column; justify-content:center; align-items:center;

    &.active{
      ${ Span }{
        &:nth-child( 1 ){ transform:rotate( 45deg ) translate3d( 6px, 6px, 0); }
        &:nth-child( 2 ){ opacity:0; }
        &:nth-child( 3 ){ transform:rotate( -45deg) translate3d( 5px, -5px, 0); }
      }
    }
  `