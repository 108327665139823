export const Data = {
  title:'Подходит для разных отраслей',
  list:[
    {
      title:'Энергетика', icon:2, class:'industries energo',
      text:'Компании энергосбережения, ТЭЦ, ГЭС, государственные структуры, отвечающие за контроль энергообъектов'
    },
    {
      title:'Здравохранение', icon:4, class:'industries heart',
      text:'Больницы, медицинские исследовательские центры и НИИ, поликлиники, организации скорой помощи, санатории'
    },
    {
      title:'Образование', icon:1, class:'industries brain',
      text:'Системы управления образованием федерального, регионального и муниципального уровней, университеты, библиотечные комплексы, ссузы'
    },
    {
      title:'Государственные органы', icon:3, class:'industries gos',
      text:'Министерства и ведомства, органы государственной власти и местного самоуправления'
    }
  ]
}