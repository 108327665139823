import React from "react";
import { PrimaryContainer } from "../../../../Shared/Styles/PrimaryStylesGroup/Container";
import { Typography, Row } from "antd";
import { WhatSystemDoData } from "../../../../Shared/Localization/IntellessiPage/what.system.do";
import { CSSList } from "./styles";
import { size } from "../../../../Shared/consts/MediaQueries";

export const { Title, Paragraph } = Typography;

export const WhatSystemDo: React.FC = () => {
  return (
    <PrimaryContainer $margin={40}>
      <Title
        level={3}
        style={{
          color: "#13087A",
          fontSize: size.mobileL >= window.innerWidth ? "35px" : "50px",
        }}
      >
        {WhatSystemDoData.title}
      </Title>

      <Row justify={"center"} align={"middle"}>
        {WhatSystemDoData.list.map((str: string, index: number) => (
          <CSSList key={index}>
            <Paragraph
              style={{ fontSize: "18px", margin: "0px", textAlign: "center" }}
            >
              {str}
            </Paragraph>
          </CSSList>
        ))}
      </Row>
    </PrimaryContainer>
  );
};
