export interface IList {
  title: string;
  description: string;
}

export const Data = {
  title: "САБПЭК",
  subtitle: "Комплексное решение задач",
  list: [
    {
      title: "Цифровая трансформация",
      description: "Цифровые технологии в системе управления энергокомпанией",
    },
    {
      title: "Прозрачность бизнес-процессов",
      description:
        "Создание единого информационного пространства работы компании",
    },
    {
      title: "Контроль и онлайн-мониторинг",
      description:
        "Современный способ увеличения эффективности работы, повышение достоверности результатов деятельности",
    },
    {
      title: "Предиктивная аналитика",
      description: "Мониторинг выполнения KPI и возникновения инцидентов",
    },
  ],
};
