import styled from "styled-components";

export const CSSAdvantageHoverBlock = styled.div`
  width: 400px;
  height: auto;
  transition: 0.4s;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;

  .ant-typography,
  h5 {
    transition: 0.4s;
    color: #ffffff;
  }

  svg {
    width: 100%;
    height: 100%;
    transition: 0.4s;
    .fil0 {
      transition: 0.4s;
      fill: #ffffff;
    }
  }

  &:hover {
    background-color: #ffffff;

    .ant-typography,
    h5 {
      color: #000000;
    }

    svg {
      transition: 0.4s;
      .fil0 {
        transition: 0.4s;
        fill: #000000;
      }
    }
  }
`;
