export const DataSabpekPage = {
  header:{
    title:'САБПЭК',
    paragraph:[
      'Программный комплекс для автоматизации всего спектра бизнес-процессов сетевых энергетических компаний, их аналитики и контроля.',
      'САБПЭК обеспечивает учет сетевой инфраструктуры, технологических присоединений новых объектов к энергосетям, систематизацию работы по передаче и распределению электроэнергии, а также автотранспорта.'
    ],
    documents:[
      {
        title:'Лицензионный договор',
        type:'SabpekLA', 'file':'САБПЭК.Лицензионный_договор.pdf',
        description:'В данном документе содержится ... (ЛД)'
      },
      {
        title:'Инструкция пользователя',
        type:'SabpekUM', 'file':'САБПЭК.Инструкция_пользователя.pdf',
        description:'В данном документе содержится ... (ИП)'
      },
      {
        title:'Функциональное описание',
        type:'SabpekFD', 'file':'САБПЭК.Функциональное_описание.pdf',
        description:'В данном документе содержится ... (ФО)'
      },
      {
        title:'Инструкция по развертыванию',
        type:'SabpekDI', 'file':'САБПЭК.Инструкция_по_развертыванию.pdf',
        description:'В данном документе содержится ... (ИпР)'
      }
    ],
    btn:'Оставить заявку',
  },

  modules_sabpek:{
    title:'Модули САБПЭК',
    subtitle:'Система автоматизации бизнес-процессов энергетических компаний',
    description:'Специализированный программный продукт, позволяющий удобно и легко контролировать бизнес-процессы компании, упростить работу сотрудников, обеспечить эффективное взаимодействие отделов и служб.',
    modules:[
      'Коммерческий учет', 'Балансы электроэнергии', 'Технологическое присоединение', 'ОРЭО', 'Ремонтная программа', 'Управление надежностью',
      'Оперативное управление', 'Автотранспорт', 'Мобильные решения', 'НСИ', 'ГИС', 'Инциденты'
    ]
  },
  task_list:{
    title:'Решаемые бизнес-задачи',
    subtitle:'Система автоматизации бизнес-процессов энергетических компаний',
    list:[
      'Транспорт и распределение электрической энергии',
      'Технологическое присоединение',
      'Выявление и предупреждение технологических нарушений эксплуатации электрических сетей',
      'Организация надзора и контроля в электрических сетях и электроустановках потребителей',
      'Осуществление ремонтов, дефектовок и инвентаризации электротехнического оборудования',
      'Развитие и реконструкция электрической сети',
      'Снижение рисков травматизма персонала и сторонних лиц на объектах электросетевого комплекса',
      'Управление автотранспортными ресурсами компании',
      'Обеспечение надежности энергоснабжения',
      'Локализация очагов хищения',
      'Снижение потерь электрической энергии',
      'Планирование и контроль работ в режиме онлайн'
    ]
  }
}