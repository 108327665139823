import React from 'react'

import { FloatButton } from 'antd'

import { Header } from 'Widgets/Header'
import { Footer } from 'Widgets/Footer'
import { Navigation } from 'Widgets/Navigation'

import { PrincipleInteraction } from 'Widgets/MobappGroup/PrincipleInteraction'
import { EffectImplementation } from 'Widgets/MobappGroup/EffectImplementation'
import { MobileSolutions } from 'Widgets/MobappGroup/MobileSolutions'
import { DataTransferProcess } from 'Widgets/MobappGroup/DataTransferProcess'

import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

import { Data } from '../Data/data'

import { ReactComponent as IcMobapp } from 'Shared/Assets/images/mobapp/IcMobileSolutions.svg'

export const MobappPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
        <Header
            header={ { title:Data.title , paragraph:Data.paragraph, btn:Data.btn }  }
            Image={ IcMobapp } documents={ null } sizeIcon={ 650 } />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <MobileSolutions />
        <PrincipleInteraction />
        <DataTransferProcess />
        <EffectImplementation />
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}