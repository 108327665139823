import React from 'react'

import { Carousel, ConfigProvider } from 'antd'

import { PartnersCollapse } from 'Shared/UI/CollapseGroup/PartnersCollapse'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

import { ReactComponent as IcAvitist } from 'Shared/Assets/images/partners/IcAvitist.svg'
import { ReactComponent as IcAlmiPartner } from 'Shared/Assets/images/partners/ALMI_Partner.svg'
import { ReactComponent as IcAstra } from 'Shared/Assets/images/partners/Astra.svg'
import IcKodSecurity from 'Shared/Assets/images/partners/SC.png'
import { ReactComponent as IcParus } from 'Shared/Assets/images/partners/Parus.svg'
import { ReactComponent as IcRostelecom } from 'Shared/Assets/images/partners/RGB_RT_horizontal_main_ru.svg'
import { ReactComponent as IcEfforGroup } from 'Shared/Assets/images/partners/EffortGroup.svg'
import { ReactComponent as IcDba } from 'Shared/Assets/images/partners/IcDBA.svg'
import { ReactComponent as IcDirectum } from 'Shared/Assets/images/partners/IcDirectum.svg'
import { ReactComponent as IcFlups } from 'Shared/Assets/images/partners/Fplus_Blue.svg'
import { ReactComponent as IcMont } from 'Shared/Assets/images/partners/MONT.svg'
import { ReactComponent as IcOcs } from 'Shared/Assets/images/partners/OCS.svg'
 
const CarouselContainer = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    height:150px;
    text-align:center;

    @media ${ device.maxMobileL } { height:auto; }
  `
const IcPartners = styled.div`
    border: ${ Grids ? '1px solid green':'none' };
    width:100%!important; height:auto;
    
    svg, img{
      width:25%; height:auto;
      text-align:center;
    }

    @media ${ device.maxMobileL } { svg,img { width:70%; } }
  `
const CollapseContainer = styled.div`
    border: ${ Grids ? '1px solid darkorange':'none' };
    margin-top:40px;

    @media ${ device.maxMobileL } { margin:40px 10px; }
  `

const DataImage = [
  IcAvitist, IcAlmiPartner, IcAstra, IcKodSecurity,
  IcParus, IcRostelecom, IcEfforGroup, IcDba,
  IcDirectum, IcFlups, IcMont, IcOcs,
]

export const PartnersInfo:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <CarouselContainer>
        <ConfigProvider
            theme={ {
              token:{
                colorBgContainer:'#1677FF', controlHeightSM:-20
              }
            } }>
        <Carousel autoplay>
          {
            DataImage.map( (Item:React.ElementType, index:number ) => 
                <IcPartners key={ index }>
                  {
                    typeof Item == 'string'
                      ? <p style={ { display:'flex', alignItems:'center', justifyContent:'center' } }>
                          <img src={ Item } alt='icon' />
                        </p>
                      : <Item />
                  }
                </IcPartners>
            )
          }
        </Carousel>
        </ConfigProvider>
      </CarouselContainer>
      <CollapseContainer>
        <PartnersCollapse />
      </CollapseContainer>
    </PrimaryContainer>
  )
}