import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import
  IcEffectsImplementation_With
from 'Shared/Assets/images/dias.page/EffectsImplementation_With.png'
import
  IcEffectsImplementation_Without 
from 'Shared/Assets/images/dias.page/EffectsImplementation_Without.png'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; justify-content:center; align-items:center;

    @media ${ device.maxMobileL } { flex-direction: column; }
  `

const Icon = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:500px; height:auto; margin:20px;

    img{ width:100%; height:auto; }

    @media ${ device.maxMobileL } { width:auto; }
  `

export const EffectsImplementation:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 60 }>
      <PrimaryHeader title='Эффект от внедрения' />
      <Container>
        <Icon>
          <img src={ IcEffectsImplementation_Without } alt='icon' />
        </Icon>
        <Icon>
          <img src={ IcEffectsImplementation_With } alt='icon' />
        </Icon>
      </Container>
    </PrimaryContainer>
  )
}