import React from 'react'

import { TIconWithDescription } from './Types'

import { Box, Icon, Content } from './Styles'

export const IconWithDescription:
              React.FC< TIconWithDescription > = ( { title, text, Image, _class } ) => {
  return(
    <Box className={ _class }>
      <Icon><Image /></Icon>
      <Content>
        <h3>{ title }</h3>
        <p>{ text }</p>
      </Content>
    </Box>
  )
}

/*
<Icon>{ typeof Image == 'string' ? <img src={ Image } alt='icon' /> : <Image /> }</Icon>
      
*/