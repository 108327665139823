import React from 'react'

import { Container } from './Styles'

type TPrimaryHeader = {
  title:string,
  color?:string,
  size?:number,
}

export const PrimaryHeader:React.FC< TPrimaryHeader > = ( { title, color, size } ) => {
  return(
    <Container $color={ color } $fontSize={ size }>
      <h3>{ title }</h3>
    </Container>
  )
}