import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const Container = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
  `

export const Text = styled.div`
    border: ${ Grids ? '1px solid green' : 'none' };

    text-align:center;

    p{ font-size:14px; margin:5px auto; }
  `

export const Icon = styled.div`
    border: ${ Grids ? '1px solid blue' : 'none' };

    text-align:center;
    margin-bottom:10px;

    svg, img{ width:50%; height:auto; }
  `