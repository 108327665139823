import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const CSSIconDDoc = styled.div`
    border: ${ Grids ? '1px solid pink' : 'none' };
    margin:3px;

    svg{
      margin-left:70px; clear:both; white-space:nowrap;
      display:inline-block; margin:5px; font-size:30px; color:white;
      transition:.4s; cursor:pointer;
      
      &:hover{ color:#4096FF; }
    }
  `