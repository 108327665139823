import styled from "styled-components";

export const CSSList = styled.div(function () {
  return {
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    width: "300px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    margin: "15px",
    border: "1px solid #CFCFCF",
    boxShadow: "0 0 8px 0 #CFCFCF",
    transition: ".4s",

    "&:hover": {
      boxShadow: "0 0 20px 0 #1777F0",
    },
  };
});
