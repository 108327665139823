import React from 'react'

import { ReactComponent as IcPhone } from 'Shared/Assets/images/Phone.svg'
import { ReactComponent as IcEmail } from 'Shared/Assets/images/Email.svg'
import { ReactComponent as IcGeo } from 'Shared/Assets/images/Geo.svg'

import { Container, Icon, Text } from './Styles'

import { TContactBlock } from './Types'

export const ContactsBlock:React.FC< TContactBlock > = ( { title, description, alt } ) => {

  const icon = ( alt:string ) => {
    switch( alt ) {
      case 'IconGeo':return <IcGeo />
      case 'IconPhone':return <IcPhone />
      case 'IconEmail':return <IcEmail />
      default:return 'Error! Alt doesn\'t found!'
    }
  }

  return(
    <Container>
      <Icon>
        { icon( alt ) }
      </Icon>
      <Text>
        <h2>{ title }</h2>
        <p>{ description }</p>
      </Text>
    </Container>
  )
}