export const Data = {
  title:'ИТ Сфера', subtitle:'Услуги',
  blocks:[
    {
      title:'Разработка ПО', class:'services',
      text:'Разработка WEB и Desktop приложений', icon:'SD'
    },
    {
      title:'Создание нейронных сетей ', class:'services',
      text:'Создание, разработка и дообучение нейронных сетей', icon:'CNN'
    },
    {
      title:'Бизнес-анализ', class:'services',
      text:'Автоматизация бизнес-процессов и бизнес-анализа', icon:'ABP'
    },
    {
      title:'Аэросъемка', class:'services',
      text:'Аэросъемка с квадрокоптера в труднодоступных местах', icon:'AP'
    },
    {
      title:'Модернизация и сопровождение', class:'services',
      text:'Модернизация и сопровождение сторонних информационных систем', icon:'MM'
    },
    {
      title:'Обучение', class:'services',
      text:'Обучение персонала заказчика', icon:'ST'
    }
  ]
}