import React from 'react'
import { Link } from 'react-router-dom'

import { copyright } from './data'

import { CopyrightContainer, DateCopyright, PDP  } from './Styles'

export const Copyright:React.FC = ( ) => {
  return(
    <CopyrightContainer>
      <DateCopyright>
        <p>{ copyright.lDate } - { ( new Date().getFullYear() ) } { copyright.company }</p>
      </DateCopyright>
      <PDP>
        <Link to={ copyright.PDP.link }>{ copyright.PDP.text }</Link>
      </PDP>
    </CopyrightContainer>
  )
}