export const DataDiasPage = {
  header: {
    title: "ЦИАС",
    paragraph: [
      "Информационная система сбора, хранения, анализа и графической визуализации данных для контроля за ключевыми показателями.",
      "Это гибкий механизм для оперативного принятия управленческих решений, основное предназначение которого визуализация показателей.",
    ],
    documents: [
      {
        title: "Лицензионный договор",
        type: "DiasLA",
        file: "ЦИАС.Лицензионный_договор.pdf",
        description: "...",
      },
      {
        title: "Руководство пользователя",
        type: "DiasUM",
        file: "ЦИАС.Инструкция_пользователя.pdf",
        description: "...",
      },
      {
        title: "Инструкция по установке",
        type: "DiasDI",
        file: "ЦИАС.Инструкция_по_установке.pdf",
        description: "...",
      },
    ],
    btn: "Оставить заявку",
  },

  registry: {
    title: "Отечественное и импортонезависимое",
    sub_title: "ЦИАС включена в Единый реестр Российского ПО",
    paragraph: [
      "Система разворачивается в закрытом контуре. Возможность использования ПО при любом уровне цифровой зрелости компании. Всего 3 дня требуется на развертывание ядра системы и 7 дней до базовой конфигурации. Внедрение не затрагивает существующие системы.",
    ],
    kod: "№6860",
    link: "https://reestr.digital.gov.ru/reestr/308210/?sphrase_id=1541821",
  },

  industries: {
    title: "Подходит для разных отраслей",
    list: [
      {
        title: "Энергетика",
        icon: "Electro",
        class: "electro",
        paragraph:
          "Компании энергосбережения, ТЭЦ, ГЭС, государственные структуры, отвечающие за контроль энергообъектов",
      },
      {
        title: "Здравохранение",
        icon: "Heart",
        class: "heart",
        paragraph:
          "Больницы, медицинские исследовательские центры и НИИ, поликлиники, организации скорой помощи, санатории",
      },
      {
        title: "Образование",
        icon: "Brain",
        class: "brain",
        paragraph:
          "Системы управления образованием федерального, регионального и муниципального уровней, университеты, библиотечные комплексы, ссузы",
      },
      {
        title: "Государственные органы",
        icon: "Gos",
        class: "gos",
        paragraph:
          "Министерства и ведомства, органы государственной власти и местного самоуправления",
      },
    ],
  },

  effect_implementation: {
    title: "Какой эффект от внедрения?",
    weekday: ["Пн.", "Вт.", "Ср.", "Чт.", "Пт."],
    why_dias: {
      title: "10 причин выбрать ЦИАС",
      subtitle: "Выбор за Цифровой адаптацией",
      list: [
        "Увеличение продуктивности бизнес-процессов",
        "Наглядность и оперативность принятия решений",
        "Построение аналитических выгрузок в любых разрезах с нужной степенью детализации",
        "Упрощение процедуры сопоставления и приведения данных из внешних систем к общему виду",
        "Сокращение времени и трудозатрат на сбор, обработку и консолидацию данных",
        "Геоинформационная подсистема",
        "Локальный и облачный варианты поставки",
        "Поддержка на всей территории Российской Федерации",
        "Гибкая лицензионная политика",
        "Полностью отечественное программное обеспечение",
      ],
    },
  },
};
