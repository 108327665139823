import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Container = styled.div`
    border: ${ Grids ? '1px solid red' : 'none' };
    display:flex; justify-content:flex-start; align-items:center;
    position:relative; left:-15px;
    margin:10px;

    @media ${ device.mobileS } { margin:0; width:350px; justify-content:flex-start; }
    @media ${ device.laptopL } { width:350px; }
  `
export const Icon = styled.div`
    border: ${ Grids ? '1px solid pink' : 'none' };

    width:80px; height:80px;

    svg{ width:100%; height:auto; fill:#FFFFFF; }
  `

export const Text = styled.div`
    border: ${ Grids ? '1px solid pink' : 'none' };

    h2{ font-size:26px; color:#FFFFFF; }
    p{ font-size:16px; padding-top:10px; color:#878787; }
  `