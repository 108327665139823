import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    width:1280px; margin:0 auto;

    @media ${ device.maxMobileL } { width:auto; margin:0 10px; }
  `

export const LayerContact = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    display:flex; justify-content:space-between; align-items:center;
    border-bottom:2px solid #CFCFCF;

    @media ${ device.maxMobileL } { flex-direction:column-reverse; }
  `

export const LayerInfoAndNav = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    display:flex; justify-content:space-between; align-items:start;
    margin:20px 0 0 0;

    @media ${ device.maxMobileL } {
      flex-direction:column; align-items:center;
    }
  `

export const LayerCopyright = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    display:flex; justify-content:space-between; align-items:start;
    padding:20px 0 10px 0;
  `

export const BlockCompanyInfo = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width:350px;
  `

export const BlockQuickLinks = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width:350px;

    @media ${ device.maxMobileL } { margin:20px auto; }
  `

export const BlockFooterFeedback = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width:350px;
  `