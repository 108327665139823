import React from 'react'

import { ReactComponent as IcCircle } from 'Shared/Assets/images/mobapp/IcCircle.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

const Circle = styled.div<{ $left?:number }>`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:250px; height:250px;
    position:relative;

    ${ ( props ) => { if( props.$left ) { return 'left:' + props.$left + 'px' } } };

    svg{ width:100%; height:auto; position:absolute;   }
  `
const Content = styled.div`
    border: ${ Grids ? '1px solid blue':'none' };
    width:250px; height:250px;
    display:flex; align-items:center; justify-content:center;
    text-align:center;

    p{ width:150px; line-height:23px; font-size:22px; }
  `

export const CircleBlock:React.FC<{ title:string, left:number }> = (
  { title, left }
) => {
  return(
    <Circle $left={ left }>
      <IcCircle />
      <Content>
        <p>{ title }</p>
      </Content>
    </Circle>
  )
}

/*
const Circle = styled.div`
    background: -webkit-linear-gradient(left top, #64E1E7, #004CAD);
    width: 200px;
    height: 200px;
    border-radius: 1000px;
    padding: 5px;
  `
const Box = styled.div`
    background:#FFFFFF;
    width: 200px;
    height: 200px;
    border-radius: 1000px;

    display:flex; align-items:center; justify-content:center;
    text-align:center;
  `

export const CircleBlock:React.FC<{ title:string }> = ( { title } ) => {
  return(
    <Circle>
      <Box>
        <h3>{ title }</h3>
      </Box>
    </Circle>
  )
}
*/