import React from 'react'
import { Link } from 'react-router-dom'

import {
  ImageLinkContainer, Icon, Description,
} from './Styles'

import { TImageLink } from './Type'

export const ImageLink:React.FC< TImageLink > = ( { Image, description, link } ) => {
  return(
    <ImageLinkContainer>
      <Icon>
        {
          typeof Image == 'string' ? (
            <Link to={ link }><img src={ Image } alt='icon' /></Link>
          ) : (
            <Link to={ link }><Image /></Link>
          )
        }
      </Icon>
      <Description>
        <p>{ description }</p>
      </Description>
    </ImageLinkContainer>
  )
}