import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { ReactComponent as Ic1c } from 'Shared/Assets/images/sabpek.page/DataIntegration/1c.svg'
import { ReactComponent as IcBingMaps } from 'Shared/Assets/images/sabpek.page/DataIntegration/BingMaps.svg'
import { ReactComponent as IcGoogleMaps } from 'Shared/Assets/images/sabpek.page/DataIntegration/GoogleMaps.svg'
import { ReactComponent as IcRosneft } from 'Shared/Assets/images/sabpek.page/DataIntegration/Rosneft.svg'
import { ReactComponent as IcSpaceTeam } from 'Shared/Assets/images/sabpek.page/DataIntegration/SpaceTeam.svg'
import { ReactComponent as IcStek } from 'Shared/Assets/images/sabpek.page/DataIntegration/Stek.svg'
import { ReactComponent as IcYandexMaps } from 'Shared/Assets/images/sabpek.page/DataIntegration/YandexMaps.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; flex-wrap:wrap; align-items:center; justify-content:center;
    margin:20px;
  `
const IconCompany = styled.div`
    border: ${ Grids ? '1px solid darkorange':'none' };
    width:180px; height:auto; margin:40px;

    svg{ width:100%; height:auto; }
  `

export const DataIntegration:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title='Интеграция данных со сторонними системами' />
      <Container>
        <IconCompany><Ic1c /></IconCompany>
        <IconCompany><IcBingMaps /></IconCompany>
        <IconCompany><IcGoogleMaps /></IconCompany>
        <IconCompany><IcRosneft /></IconCompany>
        <IconCompany><IcSpaceTeam style={{ width:120 }} /></IconCompany>
        <IconCompany><IcStek /></IconCompany>
        <IconCompany><IcYandexMaps /></IconCompany>
      </Container>
    </PrimaryContainer>
  )
}