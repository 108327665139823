import React from 'react'

import { EffectsIntroductionBlock } from 'Shared/UI/BlocksGroup/EffectsIntroductionBlock'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'
import { PushList } from 'Shared/Styles/PrimaryStylesGroup/PushList'

import { Data, IList } from '../Data/data'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; justify-content:space-around; align-items:center;

    @media ${ device.maxMobileL }{ flex-direction:column; }
  `
const List = styled.div`
    padding-left:30px;

    @media ${ device.maxMobileL } { padding-left:15px; }
  `

export const EffectImplementation:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title={ Data.title } />

      <Container>
        {
          Data.list.map( ( item:IList, index:number ) =>
              <EffectsIntroductionBlock key={ index }
                  title={ item.title }
                  text={ item.description }
                />
          )
        }
      </Container>
      <List>
        <PushList>
          { Data.ullist.map( ( item:string, index:number ) => <li key={ index }>{ item }</li> ) }
        </PushList>
      </List>
    </PrimaryContainer>
  )
}