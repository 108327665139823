import React from "react";
import { PrimaryContainer } from "../../../../Shared/Styles/PrimaryStylesGroup/Container";
import { Typography, Row } from "antd";
import { AboutIntellessiData } from "../../../../Shared/Localization/IntellessiPage/about.intellessi";
import AboutIntellessiImage from "../../../../Shared/Assets/images/intellessi/AboutIntellessiImage.svg";
import { size } from "../../../../Shared/consts/MediaQueries";

const { Title, Paragraph } = Typography;

export const AboutIntellessi: React.FC = () => {
  return (
    <PrimaryContainer $margin={40}>
      <Title
        level={3}
        style={{
          color: "#13087A",
          fontSize: window.innerWidth <= size.mobileL ? "35px" : "50px",
        }}
      >
        {AboutIntellessiData.title}
      </Title>

      <Row justify={"space-evenly"} align={"middle"}>
        <img
          src={AboutIntellessiImage}
          alt="About_Intellessi_Image"
          style={{
            width: size.mobileL >= window.innerWidth ? "100%" : "500px",
            height: "auto",
          }}
        />

        <Row style={{ width: "500px" }}>
          <Paragraph
            style={{
              marginBottom: "20px",
              fontSize: "18px",
              textAlign: "justify",
            }}
          >
            {AboutIntellessiData.text}
          </Paragraph>
        </Row>
      </Row>
    </PrimaryContainer>
  );
};
