import React, { type CSSProperties } from 'react'

import { CaretRightOutlined } from '@ant-design/icons'
import { type CollapseProps, Collapse, theme, ConfigProvider } from 'antd'

import { ModulesSoftwareCollapse } from 'Shared/UI/CollapseGroup/ModulesSoftwareCollapse'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { Data } from '../Data/data'

import { ReactComponent as IcAuto } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcAuto.svg'
import { ReactComponent as IcBalances } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcBalances.svg'
import { ReactComponent as IcCA } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcCommercialAccounting.svg'
import { ReactComponent as IcMobapp } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcMobapp.svg'
import { ReactComponent as IcOM } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcOperationalManagement.svg'
import { ReactComponent as IcOreo } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcOreo.svg'
import { ReactComponent as IcTC } from 'Shared/Assets/images/sabpek.page/ModulesSoftware/IcTechnologicalConnection.svg'

import styled from 'styled-components'
import { device } from 'Shared/consts/MediaQueries'
const Container = styled.div`
    @media ${ device.maxMobileL } { margin:0 10px; }
  `

// TODO: Переделать использование Data для <CollapsedCholdren />
// TODO: Попробовать перенести функцию getItem в файл ../Data/data.ts

export const ModulesSoftwarePackage:React.FC = ( ) => {

  const { token } = theme.useToken()

  const panelStyle:React.CSSProperties = {
    marginBottom:24,
    background:token.colorFillAlter,
    borderRadius:token.borderRadiusLG,
    border:'none',
  }

  const getItem:( panelStyle:CSSProperties ) => CollapseProps[ 'items' ] = ( panelStyle ) => [
    {
      key:'1', label:Data[0].title, style:panelStyle,
      children:<ModulesSoftwareCollapse description={ Data[0].description } list={ Data[0].list } Image={ IcOreo } />,
    },
    {
      key:'2', label:Data[1].title, style:panelStyle,
      children:<ModulesSoftwareCollapse description={ Data[1].description } list={ Data[1].list } Image={ IcTC } />,
    },
    {
      key:'3', label:Data[2].title, style:panelStyle,
      children:
          <ModulesSoftwareCollapse
              description={ Data[2].description }
              list={ Data[2].list }
              Image={ IcCA }
              sizeIcon='250px'
            />,
    },
    {
      key:'4', label:Data[3].title, style:panelStyle,
      children:<ModulesSoftwareCollapse description={ Data[3].description } list={ Data[3].list } Image={ IcOM } />,
    },
    {
      key:'5', label:Data[4].title, style:panelStyle,
      children:
          <ModulesSoftwareCollapse
              description={ Data[4].description }
              list={ Data[4].list }
              Image={ IcBalances }
              sizeIcon='250px'
            />,
    },
    {
      key:'6', label:Data[5].title, style:panelStyle,
      children:<ModulesSoftwareCollapse description={ Data[5].description } list={ Data[5].list } Image={ IcAuto } />,
    },
    {
      key:'7', label:Data[6].title, style:panelStyle,
      children:<ModulesSoftwareCollapse description={ Data[6].description } list={ Data[6].list } Image={ IcMobapp } />,
    },
  ]

  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title='Модули программного комплекса' />
      
      <ConfigProvider
          theme={ {
            token:{ colorText:'#000000', colorTextHeading:'#000000' }
          } }>
        <Container>
          <Collapse
              bordered={ false }
              defaultActiveKey={ ['1'] }
              expandIcon={ ( { isActive } ) => <CaretRightOutlined rotate={ isActive ? 90 : 0 } /> }
              style={ { background:token.colorBgContainer, marginTop:30, color:'black' } }
              items={ getItem( panelStyle ) }
            />
        </Container>
      </ConfigProvider>
    </PrimaryContainer>
  )
}