import React from 'react'

import { CircleBlock } from 'Shared/UI/BlocksGroup/CircleBlock'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { Data, IList } from '../Data/data'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { size } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; justify-content:center; margin:30px auto;
  `


export const PrincipleInteraction:React.FC = ( ) => {
  return(
    <PrimaryContainer
        $margin={ 40 }
        style={ { display: window.innerWidth <= size.tablet ? 'none':'block' } }
      >
      <PrimaryHeader title={ Data.title } />

      <Container>
        {
          Data.list.map( ( item:IList, index:number ) => 
              <CircleBlock key={ index } title={ item.title } left={ item.left } />
          )
        }
      </Container>
    </PrimaryContainer>
  )
}