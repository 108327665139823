import React from 'react'

import { FloatButton } from 'antd'

import { Header } from 'Widgets/Header'
import { Footer } from 'Widgets/Footer'
import { Navigation } from 'Widgets/Navigation'
import { ModulesSoftwarePackage } from 'Widgets/SabpekGroup/ModulesSoftwarePackage'
import { ComprehensiveProblemSolving } from 'Widgets/SabpekGroup/ComprehensiveProblemSolving'
import { DataIntegration } from 'Widgets/SabpekGroup/DataIntegration'
import { SolvedBusinessTasks } from 'Widgets/SabpekGroup/SolvedBusinessTasks'
import { EffectsIntroduction } from 'Widgets/SabpekGroup/EffectsIntroduction'
import { RegisterDomesticSoftware } from 'Widgets/SabpekGroup/RegisterDomesticSoftware'

import { GeoIS } from 'Widgets/IndexGroup/GeoIS'

import { DataSabpekPage } from 'Shared/consts/DataSabpekPage'

import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

import { ReactComponent as IcSabpek } from 'Shared/Assets/images/sabpek.page/Header_Sabpek.svg'

export const SabpekPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
        <Header 
          header={
            { title:DataSabpekPage.header.title, paragraph:DataSabpekPage.header.paragraph, btn:DataSabpekPage.header.btn }
          }
          Image={ IcSabpek } documents={ DataSabpekPage.header.documents } sizeIcon={ 650 } />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <ComprehensiveProblemSolving />
        <ModulesSoftwarePackage />
        <GeoIS />
        <SolvedBusinessTasks />
        <DataIntegration />
        <EffectsIntroduction />
        <RegisterDomesticSoftware />
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}