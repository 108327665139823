export const Data = {
  title:'Зачем бизнесу цифровая адаптация?',
  list:[
    {
      title:'Снижать операционные издержки', icon:1, class:'why-dias',
      text:'Уменьшайте операционные затраты за счет прозрачных процедур контроля и автоматизации обработки информации'
    },
    {
      title:'Работать на перспективу', icon:2, class:'why-dias',
      text:'Трансформируйте процессы из аналоговых в цифровые, чтобы повысить потенциальную рентабельность бизнеса'
    },
    {
      title:'Уменьшать человеческий фактор', icon:3, class:'why-dias',
      text:'Отслеживайте выполнение планов по объективным показателям, которые сложно фальсифицировать'
    }
  ]
}