import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const QLContainer = styled.div`
    border: ${ Grids ? '1px solid darkorange' : 'none' };

    h2{
      font-size:26px; color:#FFFFFF;
      position:relative; margin-bottom:15px;

      &::after{
        content:'';
        border:1px solid #00B4E9; width:95px;
        position:absolute; left:0; top:32px;
      }
    }

    ul{
      column-count:0; list-style-type:none;
    }
    li{
      margin-bottom:6px;

      a{
        color:#828282; text-decoration:none;
        margin:2px auto; transition:.4s;
        
        &:hover{ color:#FFFFFF; }
      }      
    }
  `