import React from 'react'

import { LinksList } from 'Shared/UI/ListGroup/LinksList'

import { QLContainer } from './Styles'
import { TQuickLinks } from './Types'

export { FooterQuickLinks } from 'Shared/consts/QuickLinks'

export const QuickLinks:React.FC< TQuickLinks > = ( { data } ) => {
  return(
    <QLContainer>
      <h2>{ data.title }</h2>
      <LinksList links={ data.links } />
    </QLContainer>
  )
}