export const WhatSystemDoData = {
  title: "Что делает система?",
  list: [
    "Обращается к переданным заказчиком данным",
    "Извлекает и предобрабатывает данные",
    "Позволяет сформировать запрос и получить конкретный/обобщенный ответ на основе разных источников со ссылками на исходники",
    "Выгружает сгенерированную информацию в структурированные файлы, в том числе в табличном виде",
    "Формирует базу «эталонных» ответов",
    "По запросу ищет информацию в интернете без передачи приватных данных в сеть",
  ],
};
