import React from 'react'

type TListDisp = { arrText:string[] }

/**
 * @param arrText - Массив строк
 * @returns 
 */
export const PrimaryList:React.FC< TListDisp > = ( { arrText } ) => {
  return(
    <ul>
      { arrText.map( ( items:string, index:number) => <li key={ index }>{ items }</li> ) }
    </ul>
  )
}