import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const MiniFormContainer = styled.div`
    border: ${ Grids ? '1px solid pink' : 'none' };

    display:flex; flex-direction:column; align-items:flex-start;

    h2{
      font-size:26px; color:#FFFFFF;
      position:relative; margin-bottom:15px;

      &::after{
        content:'';
        border:1px solid #00B4E9; width:95px;
        position:absolute; left:0; top:32px;
      }
    }

    p{ color:#828282; line-height:22px; padding:0 20px 0 0; }
  `

export const BlockDescription = styled.div`
    border: ${ Grids ? '1px solid blue' : 'none' };
  `

export const BlockMiniFeedback = styled.div`
    border: ${ Grids ? '1px solid blue' : 'none' };
    width:85%; margin:10px 0;
  `