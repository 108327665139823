export interface IList {
  title:string, left:number
}

export const Data = {
  title:'Принцип взаимодействия',
  list:[
    { title:'Мобильное приложение', left:0 },
    { title:'САБПЭК', left:-20 },
    { title:'Интеграция', left:-40 },
    { title:'Система заказчика', left:-60 },
  ]
}