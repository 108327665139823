import React from 'react'

import { ContactsBlock } from 'Shared/UI/BlocksGroup/ContactBlock'

import { TSequenceBlocksContact, TArrContacts } from './Types'

export const SequenceBlocksContact:React.FC< TSequenceBlocksContact > = ( { contacts } ) => {
  return(
    <>
      {
      contacts.map(
        ( item:TArrContacts, index:number ) => (
          <ContactsBlock
            title={ item.title }
            description={ item.description }
            alt={ item.alt }
            key={ index } />
        )
      )
    }
    </>
  )
}