import React from 'react'

import { BigDoubleHeader } from 'Shared/UI/HeaderGroup/BigDoubleHeader'
import { PrimaryList } from 'Shared/UI/ListGroup/PrimaryList'

import { Data } from '../Data/data'

import {
  Container
} from './Styles'
import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'

export const DiasInfo:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 60 }>
      <BigDoubleHeader title={ Data.title } subtitle={ Data.subtitle } />
      
      <Container>
        <PrimaryList arrText={ Data.list } />
      </Container>
    </PrimaryContainer>
  )
}