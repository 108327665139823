import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Col = styled.div<{ $width?:number }>`
    border: ${ Grids ? '1px solid blue' : 'none' };

    ${ ($width) => {
      if( $width.$width ) {
        return 'width:'+$width.$width+'px';
      } else {
        return 'width:95%';
      }
    } };
    
    height:auto;
  `

export const Title = styled.div`
    border: ${ Grids ? '1px solid green' : 'none' };

    h2{ color:#13087A; font-size:40px; line-height:35px; }
    h3{ font-size:18px; font-weight:400; margin-top:20px; }
  `

export const WorkPlace = styled.div`
    border: ${ Grids ? '1px solid yellow' : 'none' };
    margin-top:30px;
  `

export const Container = styled.div`
    border: ${ Grids ? '1px solid darkorange' : 'none' };
    width:100%; height:auto;
  `


  export const Row = styled.div`
  border: ${ Grids ? '1px solid orange' : 'none' };
  display:flex; justify-content:space-around;

  @media ${ device.mobileS } {
    flex-direction:column;

    ${ Col } { width:100%; }
  }
`