import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const LayerNavigation = styled.div<{ $clWidth:number }>`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width:1280px; margin:0 auto;
    display:flex; align-items:center;
    justify-content: ${ props =>  props.$clWidth >= 768 ? 'none' : 'space-between' };

    @media ${ device.maxMobileL } { width:auto; }
  `

export const Box = styled.div<{ width?:string }>`
    border: ${ Grids ? '1px solid orange' : 'none' };

    ${ ( props ) => { if( props.width ) { return 'width:' + props.width } } };

    figure{ text-align:center; margin:10px; img, svg{ width:110px; height:auto; padding:0 10px 0 20px; } }

    @media ${ device.maxMobileL } { width:auto; }
  `