export const size = {
  mobileS:320,
  mobileM:375,
  mobileL:425,
  tablet:768,
  laptop:1024,
  laptopM:1280,
  laptopL:1440,
  desktop:2400,
}

export const device = {
  mobileS:` ( min-width: ${ size.mobileS+'px' } ) and ( max-width: ${ size.laptop+'px' } ) `,
  mobileM:` ( min-width: ${ size.mobileM+'px' } ) `,
  mobileL:` ( min-width: ${ size.mobileL+'px' } ) `,
  maxMobileL:` ( max-width: ${ size.mobileL + 'px' } ) `,

  tablet:` ( min-width: ${ size.tablet+'px' } ) `,
  
  laptop:` ( min-width: ${ size.laptop+'px' } ) `,
  laptopM:` min-width: ${ size.laptopM + 'px' } `,
  laptopL:` ( min-width: ${ size.laptopL+'px' } ) `,
  maxLaptop:` ( max-width: ${ size.laptop + 'px' } ) `,
  
  desktop:` ( min-width: ${ size.desktop+'px' } ) `,
}