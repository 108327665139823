import styled, { keyframes } from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const ErrorContainer = styled.div<{ $height:number }>`
    border: ${ Grids ? '1px solid red' : 'none' };
    height: ${ props => props.$height !== 0 ? props.$height+'px' : 'auto' };
    display:flex; justify-content:center; align-items:center;
  `

export const Block = styled.div<{ $width:number }>`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width: ${ props => props.$width !== 0 ? props.$width+'px' : 'auto' };
    height:auto;
  `

export const Title = styled.h2`
    border: ${ Grids ? '1px solid green' : 'none' };
    font-size:32px; margin:15px; text-align:center;
    h2{ font-weight:normal; }
  `

export const Text = styled.p` font-size:18px; text-align:center; `
export const BigText = styled.h1` font-size:78px; text-align:center; `

const Err = keyframes`
    0%{ transform:translateY( 0 ); } 50%{ transform:translateY( 850px ); }
  `
export const Picture = styled.div`
    border: ${ Grids ? '1px solid green' : 'none' };
    
    svg{ width:100%; height:auto; }
    #error-1, #error-2, #error-3{ fill:black; }
    #error-2, #error-3{ animation: ${ Err } 2s infinite linear; }
  `

