import { createSlice } from "@reduxjs/toolkit";

import { TBasicSettings } from "Shared/Types/Shared.types";

const initialState: TBasicSettings = {
  EMAILJS_SERVICE_ID:'service_enyk0cf',     /** ID сервиса для отправки почты */
  EMAILJS_TEMPLATE_ID:'template_6a7yb6f',   /** ID шаблона письма, которое будет отправляться */
  EMAILJS_PUBLIC_KEY:'MKNZluSinhbwUMEaY',    /** Публичный ключ Аккаунта в Email JS */

  /** Параметры для формы обратной связи в Drawer */
  openDrawerFeedback:false,        /** Состояние открыть/закрыть Drawer Feedback */
  disabledBtnSendMessage:true,     /** Кнопка состояния отправки сообщения: true - кнопка disabled, false - enabled */
  loadingBtnSendMessage:false,     /** Анимация загрузки/ожидания на кнопке отправки формы */
  statusSendMessage:'NONE',        /** Статус отправки сообщения */

  /** Параметры для Мобильного меню */
  openDrMobileMenu:false,
}

export const BasicSettingsSlice = createSlice(
  {
    name:'BasicSettings', initialState,
    reducers:{
      // * Изменение состояния переменной disabledBtnSendMessage
      changeStateBtnSendMessege:( state ) => { state.disabledBtnSendMessage = !state.disabledBtnSendMessage },
      
      // * Изменение состояния переменной loadingBtnSendMessage
      changeLoadingBtnSendMessage:( state ) => { state.loadingBtnSendMessage = !state.loadingBtnSendMessage },

      // * Изменение состояния переменной statusSendMessage на input_status
      changeStatusSendMessage:( state, action ) => { state.statusSendMessage = action.payload },

      // * Изменение состояния переменной openDrawerFeedback -> открытие/закрытие формы обратной связи
      changeOpenDrawerFeedback:( state ) => { state.openDrawerFeedback = !state.openDrawerFeedback },

      //* Изменение состояния переменной openDrMobileMenu
      changeOpenDrMobileMenu:( state ) => { state.openDrMobileMenu = !state.openDrMobileMenu }
    }
  }
)

export const {
  changeStateBtnSendMessege,
  changeLoadingBtnSendMessage,
  changeStatusSendMessage,
  changeOpenDrawerFeedback,
  changeOpenDrMobileMenu
} = BasicSettingsSlice.actions

export default BasicSettingsSlice.reducer