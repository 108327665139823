export const MobileControllerData = {
  title:'Мобильный контроллер',
  description:'Мобильное решение для автоматизации деятельности контролеров и инспекторов с фиксацией GPS-координат и организацией удаленной работы, а также планирования и контроля обходов точек учета (поверка приборов учета, оборот пломб, уведомление на ограничение, акты, согласие на обработку персональных данных и прочее).',
  list:[
    'Формирование задания по одному и/или нескольким выбранным маршрутам: снятия показаний приборов учета, доставка квитанций, выдача уведомлений/предупреждений',
    'Точность и проведение сверки данных по различным критериям',
    'Просмотр истории фактических маршрутов за период',
    'Фотофиксация приборов учета, пломб с указанием координат, даты, времени',
    'Отображение всей необходимой для сотрудника информации о приборе учета/абоненте при обходе (ФИО, адрес, номер прибора учета, модель, число фаз, прямое или трансформаторное включение, история показаний)',
    'Наличие информации о местонахождении контроллера',
    'Получение доступа к прибору учета для изменения данных в системе в случае фиксации несоответствия текущим данным',
    'Контроль качества ввода данных (разрядности прибора учета, предыдущих показаний, среднего потребления)',
    'Импорт показаний и других данных в учетную систему в любое время',
    'Работа с приложением на любых устройствах, работающих под управлением ОС Android (смартфон, планшет)',
    'Функционирование в автономном режиме без интернета, синхронизируя данные в системе при появлении сети',
  ]
}

export const InventoryData = {
  title:'Инвентаризация',
  description:'Мобильное решение для внесения и редактирования данных элементов схемы сети и их оборудования, снятия координат, отображения текущего объекта на карте и возможностью прикрепление фотографий по объектам.',
  list:[
    'Привязка пакета данных к конкретному устройству',
    'Создание объектов схемы электрической сети и оборудования на них',
    'Ведение данных на мобильном устройстве в режиме офлайн',
    'Отображение общих данных по элементам схемы сети',
    'Автоматический съем координат и отображение маршрута сотрудника',
    'Формирование дефектной ведомости энергооборудования и объектов сети',
    'Отображение схемы сети на карте в режиме офлайн',
    'Добавление материалов по шаблону',
  ]
}

export const DefectData = {
  title:'Дефектовка',
  description:'Мобильное решение для внесения данных в систему по дефектным ведомостям энергооборудования.',
  list:[
    'Проверка состояния энергооборудования, внесение результатов',
    'Ведение данных на мобильном устройстве без использования сети передачи данных',
    'Планирование и проведение осмотров',
    'Создание и заполнение дефектных ведомостей',
    'Контроль за деятельностью персонала',
    'Возможность фотофиксации',
    'Определение геолокации',
    'Достоверизация данных',
    'Планирование ремонтной программы',
  ]
}

export const MobileOperationalFieldTeamData = {
  title:'Мобильная оперативно-выездная бригада',
  description:'Мобильное решение для определения географического местоположения сотрудника оперативно-выездной бригады в автоматическом режиме и отображения ближайшего к нему объекта электросетевого хозяйства в видимой на карте области, либо в режиме дополненной реальности.',
  list:[
    'Онлайн-информация об объектах инфраструктуры',
    'Режим дополненной реальности',
    'Медиаматериалы по объектам',
  ]
}