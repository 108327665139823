import React from 'react'

import { IconWithDescription } from 'Shared/UI/BlocksGroup/IconWithDescription'

import { Container } from './Styles'

import { ISequenceBoxIconDesc, TSequenceBoxIconDesc } from './Types'

export const SequenceBoxIconDesc:React.FC< TSequenceBoxIconDesc > = ( { data, fIcon } ) => {
  return(
    <Container>
      {
        data.map( ( item:ISequenceBoxIconDesc, index:number ) =>
              <IconWithDescription key={ index }
                  title={ item.title }
                  text={ item.text }
                  Image={ fIcon( item.icon ) }
                  _class={ item.class } />
        )
      }
    </Container>
  )
}