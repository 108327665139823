import React from "react";
import { Route, Routes } from "react-router-dom";

import { IndexPage } from "../Pages/IndexPage";
import { SabpekPage } from "../Pages/SabpekPage";
import { DiasPage } from "../Pages/DiasPage";
import { PartnersPage } from "../Pages/PartnersPage";
import { MobappPage } from "../Pages/MobappPage";
import { ErrorPage } from "../Pages/ErrorPage";
import { PolicyPage } from "../Pages/PolicyPage";
import { IntellessiPage } from "../Pages/IntellessiPage";

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={"/"} element={<IndexPage />} />

      <Route path={"/sabpek"} element={<SabpekPage />} />

      <Route path={"/dias"} element={<DiasPage />} />

      <Route path={"/partners"} element={<PartnersPage />} />

      <Route path={"/mobapp"} element={<MobappPage />} />

      <Route path={"/intellessi"} element={<IntellessiPage />} />

      <Route path={"/privacy"} element={<PolicyPage />} />

      <Route path={"*"} element={<ErrorPage />} />
    </Routes>
  );
};
