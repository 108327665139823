import React from "react";
import { Navigation } from "../../../Widgets/Navigation";
import { Header } from "../../../Widgets/Header";
import { IntellessiHeaderData } from "../../../Shared/Localization/IntellessiPage/header";
import { FloatButton } from "antd";
import { Footer } from "../../../Widgets/Footer";
import { CSSWrapper } from "../../../Shared/Styles/CSSTopWrapper";
import { WhatSystemDo } from "../../../Widgets/IntellessiGroup/WhatSystemDo";
import { AboutIntellessi } from "../../../Widgets/IntellessiGroup/AboutIntellessi";
import { FunctionalFeatures } from "../../../Widgets/IntellessiGroup/FunctionalFeatures";
import { Advantages } from "../../../Widgets/IntellessiGroup/Advantages";
import { IntegrationResult } from "../../../Widgets/IntellessiGroup/IntegrationResult";

import IntellessiHeaderImage from "../../../Shared/Assets/images/intellessi/HeaderImage.svg";

export const IntellessiPage: React.FC = () => {
  return (
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
        <Header
          header={{
            title: IntellessiHeaderData.title,
            paragraph: IntellessiHeaderData.paragraph,
            btn: IntellessiHeaderData.btn,
          }}
          Image={IntellessiHeaderImage}
          documents={IntellessiHeaderData.documents}
          sizeIcon={650}
        />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <WhatSystemDo />
        <AboutIntellessi />
        <FunctionalFeatures />
        <Advantages />
        <IntegrationResult />
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  );
};
