import React from 'react'

import { TDisplayingListLinks, ILinks } from './Types'


/**
 * @param links - Массив объектов ( { title, link } )
 * @returns 
 */
export const LinksList:React.FC< TDisplayingListLinks > = ( { links } ) => {
  return(
    <ul>
      {
        links.map( ( items:ILinks, index:number ) =>
            <li key={ index }><a href={ items.link }>{ items.title }</a></li> )
      }
    </ul>
  )
}