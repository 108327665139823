import React from "react";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { changeOpenDrMobileMenu } from "App/Configure/BasicSettingsSlice";

import styled from "styled-components";

import { Menu, ConfigProvider } from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];
type TMenu = { type: string };

const Submenu = styled.div`
  color: black;
  font-size: 14px;
  transition: 0.1s;
  &:hover {
    color: #1677ff;
  }
`;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return { key, icon, children, label, type } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to="/">О Компании</Link>, "sub1", <MailOutlined />),
  getItem("Решения", "sub2", <AppstoreOutlined />, [
    getItem(
      <Submenu>
        <Link to="/sabpek">Решение для энергетики</Link>
      </Submenu>,
      "1",
    ),
    getItem(
      <Submenu>
        <Link to="/dias">Решение для аналитики</Link>
      </Submenu>,
      "2",
    ),
    getItem(
      <Submenu>
        <Link to="/mobapp">Мобильные решения для энергетики</Link>
      </Submenu>,
      "3",
    ),
    getItem(
      <Submenu>
        <Link to="/intellessi">Интеллектуальный ассистент</Link>
      </Submenu>,
      "4",
    ),
  ]),
  getItem(<Link to="/partners">Партнеры</Link>, "4", <SettingOutlined />),
];

const rootSubmenuKeys = ["sub1", "sub2", "sub3"];

export const Menus: React.FC<TMenu> = ({ type }) => {
  const [openKeys, setOpenKeys] = React.useState(["sub1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const dispatch = useDispatch();

  return (
    <>
      {type === "desctop" ? (
        <ConfigProvider
          theme={{
            token: { fontSize: 16 },
            components: {
              Menu: {
                itemBg: "#13087A",
                iconSize: 20,
                itemColor: "white",
                itemHoverColor: "#1677ff",
              },
            },
          }}
        >
          <Menu
            mode="horizontal"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={items}
          />
        </ConfigProvider>
      ) : type === "mobile" ? (
        <Menu
          mode="inline"
          onSelect={() => dispatch(changeOpenDrMobileMenu())}
          defaultOpenKeys={["sub2", "sub3"]}
          items={items}
        />
      ) : (
        <></>
      )}
    </>
  );
};
