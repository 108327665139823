import React from 'react'

import { ComprehensiveProblemSolvingBlock } from 'Shared/UI/BlocksGroup/ComprehensiveProblemSolvingBlock'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { BigDoubleHeader } from 'Shared/UI/HeaderGroup/BigDoubleHeader'

import { Data, IList } from '../Data/data'

import { ReactComponent as Number_1 } from 'Shared/Assets/images/OtherIcons/IcNumber_1.svg'
import { ReactComponent as Number_2 } from 'Shared/Assets/images/OtherIcons/IcNumber_2.svg'
import { ReactComponent as Number_3 } from 'Shared/Assets/images/OtherIcons/IcNumber_3.svg'
import { ReactComponent as Number_4 } from 'Shared/Assets/images/OtherIcons/IcNumber_4.svg'

import styled from 'styled-components'
import { device } from 'Shared/consts/MediaQueries'
const Container = styled.div`
    width:auto; height:auto; margin-top:20px;
    display:flex; flex-wrap:wrap;
    justify-content:center; align-items:flex-start;

    @media ${ device.maxMobileL } { margin:10px; }
  `

export const ComprehensiveProblemSolving:React.FC = ( ) => {

  const getIcon = ( value:number ) => {
    switch( value ) {
      case 1:return Number_1
      case 2:return Number_2
      case 3:return Number_3
      case 4:return Number_4
      default:return ''
    }
  }

  return(
    <PrimaryContainer $margin={ 40 }>
      <BigDoubleHeader title={ Data.title } subtitle={ Data.subtitle } />
      <Container>
        {
          Data.list.map( (items:IList, index:number) => 
              <ComprehensiveProblemSolvingBlock
                  key={ index } number={ index +1 }
                  title={ items.title } description={ items.description }
                  Icon={ getIcon( index +1) }
                />
          )
        }
      </Container>
    </PrimaryContainer>
  )
}