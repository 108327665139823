import React from 'react'

import { SequenceBlocksContact } from 'Shared/Components/SequenceGroup/BlocksContacts'
import { QuickLinks } from 'Shared/Components/QuickLinks'
import { Copyright } from 'Shared/Components/Copyright'
import { MiniForm } from 'Shared/Components/Feedback.group/MiniForm'
import { ImageLink } from 'Shared/Components/ImageLink/UI/ImageLink'

import { arrContacts } from 'Shared/consts/contacts'
import { FooterDescription } from './data'

import { ReactComponent as IcITLogo } from 'Shared/Assets/images/IcITLogo_Black.svg'

import {
  LayerContact,
  LayerInfoAndNav,
  LayerCopyright,
  BlockQuickLinks,
  BlockCompanyInfo,
  BlockFooterFeedback,
  Container
} from './Styles'

import { FooterQuickLinks } from 'Shared/consts/QuickLinks'
import { MiniFormData } from 'Shared/consts/DataFeedback'

export const Footer:React.FC = (  ) => {
  return(
    <Container>
      <LayerContact>
        <SequenceBlocksContact contacts={ arrContacts } />
      </LayerContact>

      <LayerInfoAndNav>
        <BlockCompanyInfo id='contacts'>
          <ImageLink Image={ IcITLogo } description={ FooterDescription.text } link='/' />
        </BlockCompanyInfo>

        <BlockQuickLinks>
          <QuickLinks data={ FooterQuickLinks } />
        </BlockQuickLinks>

        <BlockFooterFeedback>
          <MiniForm data={ MiniFormData } />
        </BlockFooterFeedback>
      </LayerInfoAndNav>

      <LayerCopyright>
        <Copyright />
      </LayerCopyright>
    </Container>
  )
}