import React from 'react'

import { Image } from 'Shared/UI/Image'

import {
  ErrorContainer, Block, Picture,
  Title, Text, BigText } from './Styles'
import { FlexContainer } from 'Shared/Styles/CSSFlexContainers/CSSFlexContainers'

import { ReactComponent as IcError } from 'Shared/Assets/images/Error.svg'

export const Error:React.FC = ( ) => {

  const heightClient = document.documentElement.clientHeight

  return(
    <ErrorContainer $height={ heightClient -380}>
      <Block $width={ 0 }>
        <Title><h2>Неожиданно!</h2></Title>
        <FlexContainer $justify='center' $align='center'>
          <Block $width={ 80 }>
            <Text>there seems to be an</Text>
          </Block>
          <Block $width={ 370 }>
            <BigText>ERROR</BigText>
          </Block>
        </FlexContainer>
      </Block>

      <Block $width={ 250 }>
        <Picture>
          <Image Icon={ IcError } />
        </Picture>
      </Block>
    </ErrorContainer>
  )
}