import React from 'react'

import { RootState } from 'App/Configure/store'
import { useSelector, useDispatch } from 'react-redux'
import { changeOpenDrMobileMenu } from 'App/Configure/BasicSettingsSlice'

import { Drawer } from 'antd'

import { Menus } from 'Shared/UI/Menu'
import { Image } from 'Shared/UI/Image'

import { Container, Text, Icon } from './Styles'

import { phone, geo, email } from 'Shared/consts/contacts'

import IcDrHeader from 'Shared/Assets/images/Logo.png'

export const DrMobileMenu:React.FC = ( ) => {

  const openDrMobileMenu = useSelector( (props:RootState) => props.BasicSlice.openDrMobileMenu )

  const dispatch = useDispatch()

  return(
    <Drawer
        open={ openDrMobileMenu }
        onClose={ () => dispatch( changeOpenDrMobileMenu() ) }
        styles={ { body:{ padding:35 } } }
        placement='left'
        footer={ 
          <Container>
            <Icon>
              <Image Icon={ IcDrHeader } />
            </Icon>
            <Text>
              <p>Тел.: <a href={ 'tel:'+phone.description }>{ phone.description }</a></p>
              <p>Эл.почта: <a href={ 'mailto:'+email.description }>{ email.description }</a></p>
              <p>Адрес: { geo.description }</p>
            </Text>
          </Container>
        }>
      <Menus type='mobile' />
    </Drawer>
  )
}