import styled from "styled-components";

export const CSSCircle = styled.div(function () {
  return {
    backgroundColor: "#EF7F1A",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
  };
});
