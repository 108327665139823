import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { MobileController } from '../MobileController/MobileController'
import { Inventory } from '../Inventory/Inventory'
import { Defect } from '../Defect/Defect'
import { MobileOperationalFieldTeam } from '../MobileOperationalFieldTeam/MobileOperationalFieldTeam'

export const MobileSolutions:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title='Мобильные решения' />

      <MobileController />
      <Inventory />
      <Defect />
      <MobileOperationalFieldTeam />
    </PrimaryContainer>
  )
}