import React from 'react'

import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, type CollapseProps } from 'antd'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { 
  Container, Boxes,
  Title, Paragraph, Icon, 
} from '../../Styles/styles'

import { MobileOperationalFieldTeamData } from '../../Data/data'

import { ReactComponent as IcOFT } from 'Shared/Assets/images/mobapp/MobileSolutioins/IcOFT.svg'

const items:CollapseProps[ 'items' ] = [
  {
    key:'1',
    label:'Возможности мобильной оперативно-выездной бригады',
    children:
      <ul style={ { marginLeft:20 } }>
        {
          MobileOperationalFieldTeamData.list.map( ( item:string, index:number ) =>
              <li key={ index } style={ { paddingBottom:3 } }>{ item }</li>
            )
        }
      </ul>,
  }
]

export const MobileOperationalFieldTeam:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <Container $reverse='row-reverse'>
        <Boxes $width={ 650 }>
          <Title $textAlign='start'>{ MobileOperationalFieldTeamData.title }</Title>
          <Paragraph>{ MobileOperationalFieldTeamData.description }</Paragraph>
          <Collapse
              style={{margin:'5px 0'}}
              expandIcon={ ( { isActive } ) => <CaretRightOutlined rotate={ isActive ? 90 : 0 } /> }
              items={ items }
            />
        </Boxes>
        <Boxes>
          <Icon><IcOFT /></Icon>
        </Boxes>
      </Container>      
    </PrimaryContainer>
  )
}