export const AdvantagesData = {
  title: "Преимущества",
  subTitle: "использования больших языковых моделей в бизнес-процессах",
  list: [
    {
      title: "Глубокое понимание естественного языка",
      text: "Искусственный интеллект обрабатывает и понимает естественный язык лучше любой другой технологии, существовавшей до сих пор",
      icon: "icon_1",
    },
    {
      title: "Возможность развертывания on-premise",
      text: "Локально, на сервере заказчика",
      icon: "icon_2",
    },
    {
      title: "Внедрение в другие системы",
      text: "Интеграция с внутренней базой знаний компании",
      icon: "icon_3",
    },
    {
      title: "Быстрый старт и легкость использования",
      text: "Установка из «коробки» за счет предобучения на большом количестве данных, а все запросы вводятся на естественном языке",
      icon: "icon_4",
    },
  ],
};
