import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const Icon = styled.div`
    border: ${ Grids ? '1px solid darkgreen' : 'none' };
    width:50px; height:50px;
    margin:10px;
    display:flex; justify-content:center;

    svg{
      width:auto; height:auto; transition:.4s;
      .fil0{ transition:.4s; fill:#CFCFCF; }
    }
  `

export const Content = styled.div`
    border: ${ Grids ? '1px solid darkgreen' : 'none' };
    margin:10px;

    h3{ font-size:18px; margin:10px auto; color:#CFCFCF; transition:.4s; }
    p{ font-size:16px;  line-height:20px; color:#CFCFCF; transition:.4s; }
  `

export const Box = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    border-radius:10px; transition:.4s;
    margin:20px;

    &.why-dias{
      width:350px;

      h3, p{ color:#FFFFFF; }
      ${ Icon } { .fil0{ fill:#FFFFFF; } }

      &:hover{
        background-color:#FFFFFF;
  
        svg{ .fil0{ fill:black; } }
        p, h3{ color:black; }
      }
    }

    &.industries{
      width:500px; 
      background-color:#FFFFFF;
      box-shadow:0 0 10px 0 #CFCFCF;
      display:flex; justify-content:space-around;

      h3{ font-size:26px; }
      h3, p{ color:black; }

      &.energo{ ${ Icon }{ background-color:#FFFAEB; } h3{ color:#FDC500; } }
      &.brain{ ${ Icon }{ background-color:#F2F4FD; } h3{ color:#00B4E9; } }
      &.gos{ ${ Icon }{ background-color:#EEF5FC; } h3{ color:#2981D9; } }
      &.heart{ ${ Icon }{ background-color:#ECFBF6; } h3{ color:#0FC986; } }

      ${ Icon }{ text-align:center; border-radius:100%; padding:20px; };
      ${ Content }{ width:100%; height:auto; }

      &:hover{
        background-color:#FFFFFF;

        &.energo{ box-shadow:0 0 20px 0 #FDC500; }
        &.brain{ box-shadow:0 0 20px 0 #5C73EA; }
        &.gos{ box-shadow:0 0 20px 0 #2981D9; }
        &.heart{ box-shadow:0 0 20px 0 #0FC986; }
      }
    }

    &.services{
      width:350px;
      background-color:#FFFFFF; border-radius:10px;
      box-shadow:0 0 10px 0 #CFCFCF; 

      h3{ color:#000000; font-size:26px; font-weight:700; text-transform:uppercase; }

      ${ Icon } { width:160px; height:160px; }

      &:hover{
        box-shadow:0 0 20px 0 #1777F0;
      }
    }    
  `