import styled from "styled-components";

export const CSSCustomBlockList = styled.div(function () {
  return {
    border: "3px solid #EF7F1A",
    borderRadius: "5px",
    width: "370px",
    height: "125px",
    margin: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };
});

export const CSSCircle = styled.div(function () {
  return {
    backgroundColor: "#EF7F1A",
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    position: "absolute",
    left: "-25px",
  };
});
