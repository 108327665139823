export const FunctionalFeaturesData = {
  title: "Функциональные возможности",
  list: [
    "Генерация ответа на русском языке",
    "Использование сторонних инструментов для предоставления ответа пользователю",
    "Обеспечение «умного поиска» по документам компании",
    "Дозагрузка файлов для обогащения внутренней «базы знаний»",
    "Разграничение уровней доступа пользователей к данным для безопасной генерации ответов",
    "Установка в «закрытом контуре» компании (на сервере заказчика)",
  ],
};
