import styled from "styled-components";
import { device } from "Shared/consts/MediaQueries";
import { Grids } from "Shared/consts/ConstantsCommon";

export const HeaderContainer = styled.div<{ $height?: number | null }>`
  border: ${Grids ? "1px solid red" : "none"};

  margin: 0 auto;
  height: ${(props) => (props.$height ? props.$height + "px" : "auto")};
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media ${device.maxMobileL} {
    width: auto;
    height: auto;
    flex-direction: column-reverse;
  }
`;

export const Block = styled.div<{ $width?: number | null }>`
  border: ${Grids ? "1px solid orange" : "none"};

  width: ${(props) => (props.$width ? props.$width + "px" : "auto")};
  height: auto;

  @media ${device.maxMobileL} {
    width: auto;
  }
`;
export const Title = styled.h1`
  border: ${Grids ? "1px solid pink" : "none"};

  text-align: center;
  color: #ffffff;
  font-size: 56px;
  text-transform: uppercase;
  padding: 0 50px;

  @media ${device.maxMobileL} {
    font-size: 50px;
    padding: 0;
  }
`;

export const Paragraph = styled.div`
  border: ${Grids ? "1px solid pink" : "none"};

  margin: 10px 0 10px 0;
  padding: 10px 50px;
  font-size: 18px;
  color: #ffffff;
  text-align: justify;
  line-height: 30px;

  p {
    margin: 10px auto;
  }

  @media ${device.maxMobileL} {
    padding: 10px;
  }
  @media ${device.tablet} {
    padding: 10px 50px;
  }
`;

export const FuncLayer = styled.div`
  border: ${Grids ? "1px solid pink" : "none"};

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;

  &.documents {
    width: auto;
    height: auto;
  }

  &.drawer-btn {
    display: flex;
    align-items: center;
    float: right;
  }

  @media ${device.maxMobileL} {
    flex-direction: column-reverse;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const ImgHeader = styled.div<{ $widthStImg?: number | null }>`
  border: ${Grids ? "1px solid yellow" : "none"};
  width: 700px;
  position: relative;
  text-align: center;
  svg,
  img {
    width: 100%;
    height: auto;
  }

  @media ${device.maxMobileL} {
    width: 350px;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 550px;
    margin-right: 30px;
  }
`;
