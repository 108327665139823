import { configureStore } from "@reduxjs/toolkit";

import BasicSettingsSlice from "./BasicSettingsSlice";

export const store = configureStore(
  {
    reducer:{
      BasicSlice:BasicSettingsSlice
    }
  }
)

export type RootState = ReturnType< typeof store.getState >
export type AppDispatch = typeof store.dispatch