import React from "react";
import { PrimaryContainer } from "../../../../Shared/Styles/PrimaryStylesGroup/Container";
import { FunctionalFeaturesData } from "../../../../Shared/Localization/IntellessiPage/functional.features";
import { Typography, Row } from "antd";
import { CSSCircle } from "./styles";
import { size } from "../../../../Shared/consts/MediaQueries";

const { Title, Paragraph } = Typography;

export const FunctionalFeatures: React.FC = () => {
  return (
    <PrimaryContainer $margin={40} id="FunctionalFeatures">
      <Title
        level={3}
        style={{
          color: "#13087A",
          fontSize: size.mobileL >= window.innerWidth ? "35px" : "50px",
        }}
      >
        {FunctionalFeaturesData.title}
      </Title>

      <Row justify={"center"} align={"middle"}>
        {FunctionalFeaturesData.list.map((str: string, index: number) => (
          <Row
            key={index}
            justify={"start"}
            align={size.mobileL >= window.innerWidth ? "top" : "middle"}
            style={{
              margin: size.mobileL >= window.innerWidth ? "10px" : "20px",
              width: size.mobileL >= window.innerWidth ? "100%" : "500px",
            }}
          >
            <CSSCircle>
              <Paragraph
                style={{
                  margin: "0px",
                  fontSize: "18px",
                  color: "#FFFFFF",
                  fontWeight: 600,
                }}
              >
                {index + 1}
              </Paragraph>
            </CSSCircle>

            <Paragraph
              style={{
                margin: "0px 0px 0px 10px",
                width: size.mobileL >= window.innerWidth ? "280px" : "400px",
                fontSize: "18px",
              }}
            >
              {str}
            </Paragraph>
          </Row>
        ))}
      </Row>
    </PrimaryContainer>
  );
};
