import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { ReactComponent as IcWhyNowCircle } from 'Shared/Assets/images/dias.page/WhyNow/IcWhyNowCircle.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon' 
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    margin:20px auto;
    display:flex; justify-content:space-around;

    @media ${ device.maxMobileL } { flex-direction:column; }
  `
const Block = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:400px; height:auto;
    display:flex; justify-content:space-between; flex-direction:column;

    svg{ width:100%; height:auto; }
  `
const IconBlock = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:400px; height:auto;

    svg{ width:100%; height:auto; }

    @media ${ device.maxMobileL } { display:none; }
  `
const Content = styled.div`
    border: ${ Grids ? '1px solid pink':'none' };
    width:auto; height:80px;
    display:flex; justify-content:center; align-items:center;

    p{
      display:flex; align-items:center; justify-content:center;
      width:300px; height:35px; padding:20px;
      background-color:#FFFFFF; border-radius:10px;
      box-shadow:0 0 10px 0 #CFCFCF;
      text-align:center; 
      transition:.4s;

      &:hover{ box-shadow:0 0 20px 0 #1777F0; }
    }
  `


export const WhyNow:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 60 }>
      <PrimaryHeader title='Почему сейчас?' />
      <Container>
        <Block>
          <Content>
            <p>Разрозненные источники данных</p>
          </Content>
          <Content>
            <p>Различные форматы и представления информации</p>
          </Content>
          <Content>
            <p>Высокая скорость изменений и генерации данных</p>
          </Content>
        </Block>
        <IconBlock><IcWhyNowCircle /></IconBlock>
        <Block>
          <Content>
            <p>Устранение последствий вместо своевременного реагирования</p>
          </Content>
          <Content>
            <p>Ограничения использования иностранных систем</p>
          </Content>
          <Content>
            <p>Необходимость быстрого принятия решений на основе актуальных данных</p>
          </Content>
        </Block>
      </Container>
    </PrimaryContainer>
  )
}