import { createGlobalStyle } from "styled-components";

export const CSSNormalize = createGlobalStyle`
    body{ margin:0; padding:0; background-color:#F6F9FB; }

    p,a,
    h1,
    h2,
    h3,
    ul,
    li{
      font-family:'Montserrat', sans-serif !important; /* 'Inter', sans-serif */
      margin:0; padding:0;
    }

    figure, p, a{
      margin-inline-start:0; margin-inline-end:0;
      margin-block-start:0; margin-block-end:0;
    }
  `;
