import React from 'react'

import { BtnBox, Span } from './Styles'

type TBtnMobileMenu = {
  status:boolean,
  fCallback:Function,
  fDispatch:Function,
}

export const BtnMobileMenu:React.FC< TBtnMobileMenu > = ( { status, fCallback, fDispatch } ) => {
  return(
    <BtnBox className={  status ? 'active' : ''  } onClick={ () => fDispatch( fCallback() ) }>
      <Span /><Span /><Span />
    </BtnBox>
  )
}